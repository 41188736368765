import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, OutlineButton } from '../components/Buttons';
import Auth from '../layouts/Auth';
import { DataContext } from '../waitlist/DataContext';
import { Modal } from '../components/Modals';
import { ShareCard } from '../components/Cards';

const WalletAddress = () => {
    const [stakeAmount] = useContext(DataContext);
    const [copied, setCopied] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(null);
    const address = '3FZbgi29cpjq2GjdwV8eyHuJJnkLtktZc53';
    const savedUserName = localStorage.getItem('username')

    const history = useHistory();

    const GetCopied = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const ToggleModal = ({ page = null }) => {
        // alert(view);
        setPage(page);
        setShowModal((prev) => !prev);
    };

    return (
        <Auth showBackArrow={true} showPhoto={false} title={'Stake $ZIPT'}>
            <Modal showModal={showModal} toggleModal={ToggleModal}>
                {page === null && (
                    <div className="text-center">
                        <span className="text-green-600 text-5xl">
                            <ion-icon name="checkmark-circle-outline"></ion-icon>
                        </span>
                        <div className="mt-3 mb-8">
                            <p>Thank you!</p>
                            <p className="mt-4">
                                When the purchase has been confirmed, ~
                                {stakeAmount.ZIPT} $ZIPT will be added to your
                                stake.
                            </p>
                        </div>
                        <div className="flex justify-between">
                            <Button onClick={() => history.push(`/${savedUserName}/home`)}>
                                Home
                            </Button>
                            <OutlineButton onClick={() => setPage('share')}>
                                Share Profile
                            </OutlineButton>
                        </div>
                    </div>
                )}
                {page === 'share' && (
                    <div className="bg-white rounded-lg p-4">
                        <div className="font-bold text-center">
                            Share Profile
                        </div>
                        <div>
                            <ShareCard>
                                <span className="text-2xl font-semibold">
                                    🐢
                                </span>
                                <span>Share with WhatsApp</span>
                            </ShareCard>

                            <ShareCard>
                                <span className="text-2xl font-semibold">
                                    🐢
                                </span>
                                <span>Share with Twitter</span>
                            </ShareCard>

                            <ShareCard>
                                <span className="text-2xl font-semibold">
                                    🐢
                                </span>
                                <span>Copy Link</span>
                            </ShareCard>
                        </div>
                    </div>
                )}
            </Modal>
            <div className="mt-4 px-4 text-gray-600 text-sm">
                <p className="text-sm text-gray-500">
                    Please use the below wallet address and amount for the
                    transfer.
                </p>

                <div className="mt-5">
                    <p className="font-semibold">Wallet Address</p>
                    <div className="flex space-x-1 items-center">
                        <p className="">{address}</p>
                        <CopyToClipboard text={address} onCopy={GetCopied}>
                            <button className="focus:outline-none px-1 py-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                                    />
                                </svg>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>

                <div className="mt-5">
                    <p className="font-semibold">Amount</p>
                    <div className="flex space-x-1 items-center">
                        <p className="">{`${stakeAmount.ETH} ETH`}</p>
                        <CopyToClipboard
                            text={stakeAmount.ETH}
                            onCopy={GetCopied}
                        >
                            <button className="focus:outline-none px-1 py-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                                    />
                                </svg>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>

                {copied && (
                    <div className="bg-green-500 text-white text-center font-semibold mt-6 py-2 rounded-full">
                        Copied to clipboard
                    </div>
                )}

                <div className="my-8 text-center">
                    <Button onClick={ToggleModal}>
                        I have made the transfer
                    </Button>
                </div>
            </div>
        </Auth>
    );
};

export default WalletAddress;
