import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../images/zippie-logo.png';
import { DataContext } from './DataContext';

import { FullWidthButton } from '../components/Buttons';
import { OtpInput } from '../components/Inputs';
import Layout from './Layout';

const Verify = () => {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [userdata] = useContext(DataContext);
    const history = useHistory();

    const getOtp = (elem, index) => {
        if (isNaN(elem.value)) return false;
        setOtp([...otp.map((d, i) => (i === index ? elem.value : d))]);
        if (elem.value && elem.nextSibling) {
            elem.nextSibling.focus();
        }
    };

    const VerifyAccount = async () => {
        const code = otp.join('');
        alert(`Your OTP: ${code}`);
        history.push('/reserved');
    };

    return (
        <Layout>
            <div className="mb-10 flex justify-between items-center">
                <img src={logo} className="h-6" alt="Zippie ID" />

                <button
                    onClick={() => history.goBack()}
                    className="text-gray-400"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
            <div className="-mt-4 text-center">
                <h1 className="text-xl font-bold text-center">Verification</h1>
                <p className="text-gray-500 mt-2 tracking-wide">
                    A 6-digit code has been sent to
                </p>
                <p className="font-bold">{userdata.email}</p>
                <div className="text-gray-500 mt-8 -mx-2">
                    <p className="mb-4">Please enter the code below.</p>
                    <OtpInput otp={otp} handleChange={getOtp} />
                </div>
                <div className="px-2 py-4">
                    <FullWidthButton onClick={VerifyAccount}>
                        Verify
                    </FullWidthButton>
                </div>

                <div className="my-4">
                    <span>Didn't get the code?</span>
                    <span
                        className="ml-2 font-bold text-purple-600 cursor-pointer"
                        onClick={() => alert('Resend')}
                    >
                        Resend
                    </span>
                </div>
            </div>
        </Layout>
    );
};

export default Verify;
