import React from 'react';

export const Modal = ({ toggleModal, showModal, children }) => {
    return (
        <div
            className={`bg-black bg-opacity-70 absolute inset-0 justify-center items-center transition-all delay-500 duration-1000 ease-in-out z-50 ${
                showModal ? 'flex' : 'hidden'
            }`}
        >
            <div className="relative bg-white px-8 py-8 rounded-xl max-w-sm shadow-xl flex flex-col mx-8 md:mx-0">
                <button
                    onClick={toggleModal}
                    className="absolute top-2 right-2 text-xl"
                >
                    <ion-icon name="close-outline"></ion-icon>
                </button>
                {children}
            </div>
        </div>
    );
};
