import React from 'react';

const PhotoUpload = ({ src, alt, onChange }) => {
    return (
        <div className="flex flex-col justify-center items-center">
            <p className="text-gray-600 text-sm mt-2">Upload your Photo</p>
            <div className="flex justify-center items-center bg-purple-100 h-16 w-16 mt-4 rounded-full">
                <input
                    type="file"
                    className="hidden"
                    id="image"
                    accept="image/*"
                    onChange={onChange}
                    name='profile-photo'
                />
                <label
                    htmlFor="image"
                    className="text-2xl text-gray-600 cursor-pointer"
                >
                    <ion-icon name="camera-outline"></ion-icon>
                </label>
            </div>
            {src !== null && (
                <div className="w-36 h-36 mt-4 p-2 bg-purple-100 rounded-full">
                    <img
                        src={src}
                        alt={alt}
                        className="rounded-full h-32 w-32"
                    />
                </div>
            )}
        </div>
    );
};

export default PhotoUpload;
