import axios from 'axios'
import { API_URL } from '../config'
import { getMe } from './waitList'

const hex = (buffer) => {
  var digest = ''
  var view = new DataView(buffer)
  for (var i = 0; i < view.byteLength; i += 4) {
    // We use getUint32 to reduce the number of iterations (notice the `i += 4`)
    var value = view.getUint32(i)
    // toString(16) will transform the integer into the corresponding hex string
    // but will remove any initial "0"
    var stringValue = value.toString(16)
    // One Uint32 element is 4 bytes or 8 hex chars (it would also work with 4
    // chars for Uint16 and 2 chars for Uint8)
    var padding = '00000000'
    var paddedValue = (padding + stringValue).slice(-padding.length)
    digest += paddedValue
  }
  return digest
}

export const sha256 = async (str) => {
  // Get the string as arraybuffer.
  var buffer = new TextEncoder('utf-8').encode(str)
  const hash = await crypto.subtle.digest('SHA-256', buffer)
  return hex(hash)
}

export const createSignature = async (privateKey, secp256k1, type) => {
  const hashInstruction = await sha256(type)
  const sig = await secp256k1.signWith(privateKey.privkey, hashInstruction)
  const signature = { rs: sig.signature, recovery: sig.recovery }
  return signature
}

export const signupToken = async (
  pubkey,
  name,
  email,
  privateKey,
  secp256k1
) => {
  try {
    const signature = await createSignature(privateKey, secp256k1, 'signup')
    const response = await axios.post(`${API_URL}/auth/signup`, {
      pubkey: pubkey,
      name: name,
      email: email,
      signature: signature,
    })
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      return error.request.response
    } else {
      console.log(`Error: ${error.message}`)
      return
    }
  }
}

export const signinToken = async (pubkey, email, privateKey, secp256k1) => {
  try {
    const signature = await createSignature(privateKey, secp256k1, 'signin')
    const response = await axios.post(`${API_URL}/auth/signin`, {
      pubkey: pubkey,
      email: email,
      signature: signature,
    })
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      return error.request.response
    } else {
      console.log(`Error: ${error.message}`)
      return
    }
  }
}

export const getToken = async (privateKey, secp, userInfo) => {
  let response = await signinToken(
    secp.sender.pubkey,
    userInfo.email,
    privateKey,
    secp.secp256k1 // secp
  )

  console.log(response)
  if (!response  || response?.data?.code === 404) {
    response = await signupToken(
      secp.sender.pubkey,
      userInfo.fullname,
      userInfo.email,
      privateKey,
      secp.secp256k1 // secp
    )
  }
  return response.data.token
}
