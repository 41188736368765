import React from 'react';

export const GuestCard = ({ children }) => {
    return (
        <div className="flex min-h-screen w-full justify-center items-center px-4 py-4 sm:px-0 sm:py-0">
            <div className="relative flex flex-col bg-white h-auto w-full shadow-xl rounded-xl px-4 sm:px-12 py-12 items-center justify-center sm:max-w-sm">
                {children}
            </div>
        </div>
    );
};

export const ListCard = ({ title, children }) => {
    return (
      <div className="bg-gray-100 rounded-xl px-4 py-4 mt-4 sm:mt-0">
        <h1 className="text-md text-gray-800 font-semibold mb-4">{title}</h1>
        <div className="h-full w-full">{children}</div>
      </div>
    )
};

export const UserCard = ({
    avatar = 'https://pickaface.net/gallery/avatar/shakil_2553b93e50d6656.png',
    username,
    stake,
}) => {
    return (
        <div className="flex space-x-2 justify-start items-center bg-white rounded-lg px-1 py-2 mb-2 sm:mb-4 sm:mx-2 sm:pl-4">
            <img
                src={avatar}
                alt={username}
                className="h-10 w-10 rounded-full"
            />
            <div className="flex flex-col">
                <p className=" text-gray-900">@{username}</p>
                <p className="text-gray-500 text-xs">{stake}</p>
            </div>
        </div>
    );
};

export const FeatureCard = ({ isSelected = false, children, isDisabled }) => {
    return (
        <div
            className={`flex flex-col bg-white items-center content-center rounded-md shadow-md py-4 text-gray-500 text-xs px-2 border-transparent border-2 cursor-pointer ${
                isSelected ? 'border-purple-600' : ''
            } ${isDisabled ? 'bg-gray-100' : ' hover:border-purple-600 focus:border-purple-600'}`}
        >
            {children}
        </div>
    );
};

export const InviteCard = ({ children }) => {
    return (
        <div className="flex bg-gray-50 rounded-full justify-between items-center content-center px-3 py-1.5 text-gray-500 text-xs border-transparent border-2 cursor-pointer my-2">
            {children}
        </div>
    );
};

export const ShareCard = ({ children }) => {
    return (
        <div className="flex bg-transparent rounded-full space-x-2 items-center content-center px-3 py-1.5 text-gray-800 text-sm border-transparent border-2 cursor-pointer my-2">
            {children}
        </div>
    );
};

export const ProfileCard = ({ onClick = null, className = '', children }) => {
    return (
      <div
        onClick={onClick}
        className={`flex bg-gray-200 rounded-xl justify-between items-center content-center px-4 py-3 text-sm border-transparent border-2 cursor-pointer my-4 ${className}`}
      >
        {children}
      </div>
    )
};