import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <>
      <h1>Admin Dashboard</h1>

      <br/>
      <hr></hr>
      <ul>
        <li><Link to="/admin">Home</Link></li>
        <li><Link to="/admin/waitlist">Waitlist</Link></li>
      </ul>
      <hr></hr>
      <br/>
    </>
  )
}

export default Home
