import React from 'react';
export const Table = ({ children }) => {
    return (
        <table className="min-w-full divide-y divide-gray-200">
            {children}
        </table>
    );
};

export const TableHead = ({ children }) => {
    return (
        <thead className="bg-gray-50">
            <tr>{children}</tr>
        </thead>
    );
};

export const TableHeading = ({ children }) => {
    return (
        <th
            scope="col"
            className="px-2 sm:px-6 py-3 text-left text-xs font-semibold text-gray-500 tracking-wider"
        >
            {children}
        </th>
    );
};

export const TableBody = ({ children }) => {
    return (
        <tbody className="bg-white divide-y divide-gray-200 text-sm">
            {children}
        </tbody>
    );
};

export const TableRow = ({ children }) => {
    return <tr>{children}</tr>;
};

export const TableData = ({ children }) => {
    return <td className="px-2 sm:px-6 py-3 text-gray-500">{children}</td>;
};
