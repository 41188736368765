import axios from 'axios'
import { API_URL } from '../config'

export const checkUsername = async (username) => {
  try {
    const response = await axios.get(
      `${API_URL}/waitlist/check_username/${username}`
    )
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      console.log(`Error in your request`)
      return
    } else {
      console.log(`Error: ${error.message}`)
      return
    }
  }
}

export const checkWaitlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/waitlist`)
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      console.log(`Error in your request`)
      return
    } else {
      console.log(`Error: ${error.message}`)
      return
    }
  }
}

export const getUsername = async (username, token) => {
  try {
    const response = await axios.get(`${API_URL}/waitlist/${username}`, {
      headers: {
        'x-access-token': token,
      },
    })
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      console.log(`Error in your request`)
      return
    } else {
      console.log(`Error: ${error.message}`)
      return
    }
  }
}

export const getMe = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/waitlist/me`, {
      headers: {
        'x-access-token': token,
      },
    })
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      console.log(`Error in your request`)
      return
    } else {
      console.log(`Error: ${error.message}`)
      return
    }
  }
}

export const reserveUsername = async (
  pubkey,
  username,
  email,
  token,
  did_ion
) => {
  try {
    const response = await axios.post(
      `${API_URL}/waitlist`,
      {
        pubkey: pubkey,
        username: username,
        email: email,
        did_ion: did_ion,
      },
      {
        headers: {
          'x-access-token': token,
        },
      }
    )
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      console.log(`Error in your request`)
      return
    } else {
      console.log(`Error: ${error.message}`)
      return
    }
  }
}

export const updateUsername = async (username, updateStatus = 'Accepted') => {
  try {
    const response = await axios.patch(`${API_URL}/waitlist/${username}`, {
      status: updateStatus,
    })
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      console.log(`Error in your request`)
      return
    } else {
      console.log(`Error: ${error.message}`)
      return
    }
  }
}

export const deleteUsername = async (username) => {
  try {
    const response = await axios.delete(`${API_URL}/waitlist/${username}`)
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      console.log(`Error in your request`)
      return
    } else {
      console.log(`Error: ${error.message}`)
      return
    }
  }
}
