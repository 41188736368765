import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDashboardContext } from '../hooks/useDashboard'
import Loading from '../components/Loading'

const Signin = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const {signIn} = useDashboardContext()

  const authWithZippie = async () => {
    setLoading(true)

    await signIn()
    setLoading(false)

  }

  return loading ? (
    <Loading />
  ) : (
    <button onClick={authWithZippie} className="text-purple-700 font-semibold text-md underline">
      Sign In
    </button>
  )
}

export default Signin
