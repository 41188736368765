import React from 'react'
import { Link } from 'react-router-dom'
import { useZippieIdContext } from '../hooks/useZippieId'

const Sidebar = () => {
  const { logout } = useZippieIdContext()
  return (
    <div className="flex flex-col w-full my-8 px-8 items-start justify-start space-y-20">
      <div className="text-4xl text-gray-700 hover:text-purple-800">
        <Link to="/">
          <ion-icon name="home-outline"></ion-icon>
        </Link>
      </div>

      <div className="text-4xl text-gray-700 hover:text-purple-800">
        <ion-icon name="notifications-outline"></ion-icon>
      </div>

      <div className="text-4xl text-gray-700 hover:text-purple-800">
        <ion-icon name="settings-outline"></ion-icon>
      </div>

      <button onClick={logout} className="text-4xl text-gray-700 hover:text-purple-800">
        <ion-icon name="log-out-outline"></ion-icon>
      </button>
    </div>
  )
}

export default Sidebar
