import React, { useState, createContext } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [userdata, setUserdata] = useState({});
    const [features, setFeatures] = useState([]);
    const [stakeAmount, setStakeAmount] = useState({
        ZIPT: null,
        ETH: null,
        USD: null,
    });

    return (
        <DataContext.Provider
            value={[
                userdata,
                setUserdata,
                features,
                setFeatures,
                stakeAmount,
                setStakeAmount,
            ]}
        >
            {children}
        </DataContext.Provider>
    );
};
