import React, {useState,useEffect} from 'react'
import { useHistory } from 'react-router'
import { OutlineButton } from '../components/Buttons'
import { useDashboardContext } from '../hooks/useDashboard'

import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from '../components/Table'
import Auth from '../layouts/Auth'

const Home = () => {
  const {getUserProfile } = useDashboardContext()
  const [userProfile, setUserProfile] = useState()

  const fetchUserInfo = async () => {

      const data = await getUserProfile()
      setUserProfile(data)
    }
    useEffect(() => {
      fetchUserInfo()
    }, [])
  const history = useHistory()
  return (
    <Auth showBackArrow={false} showPhoto={true} title={'View'} imagePath={userProfile?.imagePath}>
      <div className="flex justify-between items-center">
        <div className="">
          <p className="">{userProfile?.name || ''}</p>
          <p className="">ID: @{userProfile?.username || ''}</p>
        </div>
        <div className="flex flex-col space-y-1 text-sm">
          <OutlineButton wide={false} bold={false}>
            Add Features
          </OutlineButton>
          <OutlineButton wide={false} bold={false}>
            View Stakers
          </OutlineButton>
        </div>
      </div>

      <div className="mt-4 px-2 py-2 bg-gray-100 rounded-lg w-full min-h-8 text-sm">
        <div className="pt-2 pb-4 ml-2">About me</div>
        <div className="text-center">
          <p className="tracking-none leading-6">
            You haven’t added any features to your profile yet. Start by
            clicking the button below.
          </p>

          <div className="my-8">
            <OutlineButton onClick={() => history.push('/stake')}>
              Stake $ZIPT
            </OutlineButton>
          </div>
        </div>

        <div>
          <Table>
            <TableHead>
              <TableHeading>S/N</TableHeading>
              <TableHeading>Claim</TableHeading>
              <TableHeading>Stakers</TableHeading>
              <TableHeading>Coins Staked</TableHeading>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableData>1</TableData>
                <TableData>Twitter</TableData>
                <TableData>@zippier</TableData>
                <TableData>10</TableData>
              </TableRow>
              <TableRow>
                <TableData>2</TableData>
                <TableData>Photo</TableData>
                <TableData>@zippier</TableData>
                <TableData>25</TableData>
              </TableRow>
              <TableRow>
                <TableData>3</TableData>
                <TableData>Photo</TableData>
                <TableData>@geek</TableData>
                <TableData>69</TableData>
              </TableRow>
              <TableRow>
                <TableData>4</TableData>
                <TableData>Photo</TableData>
                <TableData>@human</TableData>
                <TableData>87</TableData>
              </TableRow>
            </TableBody>
          </Table>
          <div className="text-center font-semibold text-purple-700 py-4 underline">
            View all
          </div>
        </div>
      </div>
    </Auth>
  )
}

export default Home
