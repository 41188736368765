import React from 'react'

const Loading = () => {
  return (
    <div className="w-full h-screen absolute top-0 left-0 bg-gray-100 bg-opacity-75 flex items-center justify-center">
      <div className="min-w-md  p-8 rounded-lg flex items-center space-x-2 text-4xl text-purple-700 font-lexend">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="animate-spin h-10 w-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
        <span className="p-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-600">
          Just a moment...
        </span>
      </div>
    </div>
  )
}

export default Loading
