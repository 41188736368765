/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { API_URL } from '../../config'
import { useDashboardContext } from '../../hooks/useDashboard'

const Waitlist = () => {
  const { accessToken, isDashboardReady } = useDashboardContext()
  const [waitlistData, setWaitlistData] = useState([])

  useEffect(() => {
    if(!isDashboardReady) return
    fetchWaitlist()
  }, [isDashboardReady])

  const fetchWaitlist = async () => {
    try {
      const {data} = await axios
      .get(`${API_URL}/waitlist`, {
        headers: { 'x-access-token': accessToken }
      })
      setWaitlistData(data.waitlist)
    } catch(e) {
      console.error(e)
    }
  }

  const updateStatus = async (operation, username) => {
    let endpoint

    if(operation === 'Accept') endpoint = 'waitlist/accept_user'
    else if (operation === 'Hold') endpoint = 'waitlist/hold_user'
    else if ( operation === 'Reject') endpoint = 'waitlist/reject_user'

    await axios
    .post(`${API_URL}/${endpoint}`, 
      {
        username
      },
      {
        headers: { 'x-access-token': accessToken },
      }
    ).then(async () => {
      await fetchWaitlist()
    })
    .catch((err) => {
      console.error('STATUS UPDATE ERROR')
      console.error(err.response)
    })
  }

  return isDashboardReady ? (
    <>
      <h1>Admin Waitlist</h1>

      <br/>
      <hr></hr>
      <ul>
        <li><Link to="/admin">Home</Link></li>
        <li><Link to="/admin/waitlist">Waitlist</Link></li>
      </ul>
      <hr></hr>
      <br/>

      <table className="border-collapse border border-black">
        <thead>
          <tr>
            <th className="border border-black">Username</th>
            <th className="border border-black">Status</th>
            <th className="border border-black">Application Date</th>
            <th className="border border-black"></th>
          </tr>
        </thead>
        <tbody>
         {
            waitlistData ? waitlistData.map((i) => (
              <tr key={i.id}>
                <td className="border border-black">{i.username}</td>
                <td className="border border-black">{i.status}</td>
                <td className="border border-black">{i.created_at}</td>
                <td className="border border-black">
                  <Link to={`/admin/waitlist/${i.username}`} className="bg-blue-500 hover:bg-blue-700 text-white rounded">Details</Link>
                  { i.status !== 'Accepted' && i.status !== 'Rejected' ? <button onClick={() => updateStatus('Accept', i.username)} className="bg-blue-500 hover:bg-blue-700 text-white rounded">Accept</button> : null }
                  { i.status !== 'Accepted' && i.status !== 'Rejected' && i.status !== 'On Hold' ? <button onClick={() => updateStatus('Hold', i.username)} className="bg-blue-500 hover:bg-blue-700 text-white rounded">Hold</button> : null }
                  { i.status !== 'Accepted' && i.status !== 'Rejected' ? <button onClick={() => updateStatus('Reject', i.username)} className="bg-blue-500 hover:bg-blue-700 text-white rounded">Reject</button> : null }
                </td>
              </tr>
            )) : (
              <tr>
                <td className="border border-black" colSpan="4">No Records Found</td>
              </tr>
            ) 
          }
         
        </tbody>
      </table>
    </>
  ) : null
}

export default Waitlist
