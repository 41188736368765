import React from 'react';
import { useHistory } from 'react-router';
import { ProfileCard } from '../components/Cards';
import Auth from '../layouts/Auth';

const ViewProfile = () => {
    const history = useHistory();
    return (
        <Auth showBackArrow={false} title={'@zippier'}>
            <div className="flex flex-col justify-center items-center h-full">
                <div
                    onClick={() => history.push('/view_photo')}
                    className="flex items-center justify-center space-x-12 mb-1"
                >
                    <img
                        src="https://pbs.twimg.com/profile_images/3541863016/6ac8c451e0e45a13050ed5f3f1dd25ee.png"
                        alt="Master"
                        className="h-24 w-24 rounded-full mb-2"
                        onClick={() => history.push('/view_photo')}
                    />
                    <span className="text-purple-900">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </span>
                </div>
                <span className="text-purple-600 text-sm font-semibold mb-2 -ml-16">
                    View more
                </span>

                <div className="w-full text-gray-800">
                    <ProfileCard onClick={() => history.push('/view_name')}>
                        <span>Name: Zippier Master</span>
                        <span className="text-purple-900">
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                        </span>
                    </ProfileCard>
                    <ProfileCard onClick={() => history.push('/view_twitter')}>
                        <span>Twitter handle: @zippier</span>
                        <span className="text-purple-900">
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                        </span>
                    </ProfileCard>
                </div>
            </div>
        </Auth>
    );
};

export default ViewProfile;
