import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useZippieIdContext } from '../hooks/useZippieId'
import { Button } from '../components/Buttons'
import { ListCard, UserCard } from '../components/Cards'
import logo from '../images/zippie-logo.png'
import { WideLayout } from './Layout'
import { API_URL } from '../config'
import {useDashboardContext} from '../hooks/useDashboard'
const Intro = () => {
  const history = useHistory()
  const { isZippieIdReady, isLoggedIn, logout } = useZippieIdContext()
  const { signIn, accessToken } = useDashboardContext()
  const [mostStaked, setMostStaked] = useState([])
  const [topStakers, setTopStakers] = useState([])
  const [loading, setLoading] = useState(!isZippieIdReady)

  const ReserveName = () => {
    // setType('signUp')
    history.push('/username')
  }

  const getTopStakers = async () => {
    try {
      const { data } = await axios.get(`${API_URL}/staking/top_stakers`)
      setTopStakers(data.data)
      console.log(data.data)
    } catch (error) {
      console.log(error?.response)
    }
  }

  const getMostStaked = async () => {
    try {
      const { data } = await axios.get(`${API_URL}/staking/most_staked`)
      setMostStaked(data.data)
      console.log(data.data)
    } catch (error) {
      console.log(error?.response)
    }
  }

  const authWithZippie = async () => {
   
    setLoading(true);
    await signIn()
    setLoading(false);

  }

  useEffect(() => {
    getTopStakers()
    getMostStaked()
    setLoading(!isZippieIdReady)
  }, [isZippieIdReady])

  return isZippieIdReady ? (
    <WideLayout>
      <div className="mb-10 flex justify-between items-center px-2 md:px-4">
        <img src={logo} className="h-6" alt="Zippie ID" />
        {!accessToken ? (
          !loading && (
            <button onClick={authWithZippie} className="text-purple-700 font-semibold text-md underline">
              Sign In
            </button>
          )
        ) : (
          <Button onClick={logout}>Logout</Button>
        )}
      </div>

      <div className="-mt-4 text-center max-w-sm mx-auto">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">Have a Proof of You</h1>
        <p className="text-gray-500 mt-2 tracking-wide leading-6">
          Zippie ID is your universal username & website <br />
          that helps you prove who you are online.
        </p>

        <Page ReserveName={ReserveName} history={history} isLoggedIn={accessToken} />
      </div>
      <div className="my-4 sm:px-20 grid grid-cols-1 md:gap-12 lg:gap-y-18 lg:gap-x-24 md:grid-cols-2">
        <div className="mt-2 px-4 md:px-8 py-4">
          <h1 className="text-md text-gray-800 font-semibold mb-4">How does this work?</h1>
          <ol className="list-decimal px-4">
            <li className="mb-3 tracking-wide leading-6 sm:text-md">
              Reserve a unique username & share info about you — name, photo, Twitter, and more
            </li>
            <li className="mb-3 tracking-wide leading-6 sm:text-md">
              Invite your friends to vouch for you by staking $ZIPT. All of you will earn extra tokens as a reward.
            </li>
            <li className="mb-3 tracking-wide leading-6 sm:text-md">
              Share your website in your social media bios (Twitter, Facebook)
            </li>
          </ol>
          <div className="flex justify-between items-center">
            <p className="font-semibold mt-3"> That's it!</p>
            <h1 className="underline text-purple-600 font-bold">
              <a href="https://docs.zid.is/" target="_blank" rel="noopener noreferrer">
                Tell Me More
              </a>
            </h1>
          </div>
        </div>
        <ListCard title={'Most Staked Users'}>
          {mostStaked.slice(0, 3).map((sraker) => (
            <UserCard
              avatar={sraker.photo}
              username={sraker.username}
              stake={`${sraker.stakes.amount} (ZIPT)`}
              key={sraker.username}
            />
          ))}
        </ListCard>

        <ListCard title={'Biggest Stakers'}>
          {topStakers.slice(1, 4).map((profile) => (
            <UserCard
              avatar={profile.photo}
              username={profile.username}
              stake={`${profile.staked.amount} (ZIPT) | Earned: +${profile.staked.earned}`}
              key={profile.username}
            />
          ))}
        </ListCard>

        <ListCard title={'Best Reporters'}>
          <div className="flex sm:items-center justify-center w-full h-full">
            <p className="text-center text-gray-600">No reports yet...</p>
          </div>
        </ListCard>
      </div>
      <div className="text-center max-w-sm mx-auto">
        <Page ReserveName={ReserveName} history={history} isLoggedIn={accessToken} />
      </div>
    </WideLayout>
  ) : null
}

const Page = ({ ReserveName, history, isLoggedIn }) => {
  const savedUserName = localStorage.getItem('username')

  return isLoggedIn ? (
    <React.Fragment>
      <div className="px-2 lg:px-12 py-4">
        <Button onClick={() => history.push( `/${savedUserName}`)}>Go to Profile</Button>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="px-2 lg:px-12 py-4">
        <Button onClick={ReserveName}>Reserve your name</Button>
      </div>

      <div
        className="px-2 lg:px-12 py-2 text-purple-800 font-semibold cursor-pointer"
        onClick={() => history.push('/invite_code')}
      >
        I have an invite code
      </div>
    </React.Fragment>
  )
}

export default Intro
