import * as ION from '@zippie/ion-sdk'
import * as secp256k1 from '@transmute/did-key-secp256k1'
import { permastore, signers } from '@zippie/zippie-utils'
import crypto from 'crypto'
import fetch from 'cross-fetch'
import axios from 'axios'
import { API_URL } from '../config'
import { getMe } from './waitList'

ION.AnchorRequest = class {
  constructor(requestBody, options = {}) {
    this.body = requestBody
    this.solutionEndpoint = options.solutionEndpoint
    if (!this.solutionEndpoint) {
      this.solutionEndpoint = 'https://ion.dev.zippieid.com/operations'
    }
  }
  async submit() {
    console.log(JSON.stringify(this.body))
    const response = await fetch(this.solutionEndpoint, {
      method: 'POST',
      body: JSON.stringify(this.body),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.status >= 500) {
      console.log(`Unexpected 5xx response: ${await response.text()}`)
    } else if (response.status >= 400) {
      console.log(await response.text())
    } else if (response.status >= 300) {
      console.log(`Unexpected 3xx response: ${await response.text()}`)
    } else {
      //success
      console.log(`Successful registration`)
      const responseText = await response.text()
      console.log(responseText)
      return responseText
    }
  }
}

const deriveUpdatePrivateKeyHex = (privateKeyOrig) => {
  return crypto.createHash('sha256').update(Buffer.from(privateKeyOrig, 'hex')).update('profile/0').digest('hex')
}

// DID
export const CreateDID = async (privateKey) => {
  JSON.pretty = (json) => JSON.stringify(json, null, 2)
  const privateKeyOrig = privateKey // 'a89ff0433d6c36b336a713391b16e85ac1f9a9b6f94843679a0d6e3fec13c815';
  // console.log(privateKeyOrig)

  const derivedKeyRecoveryPrivateKeyHex = crypto
    .createHash('sha256')
    .update(Buffer.from(privateKeyOrig, 'hex'))
    .update('recovery')
    .digest('hex')

  const derivedKeyRecoveryPublicKey = secp256k1.keyUtils.publicKeyHexFromPrivateKeyHex(derivedKeyRecoveryPrivateKeyHex)

  const derivedKeyUpdatePrivateKeyHex = deriveUpdatePrivateKeyHex(privateKeyOrig)

  const derivedKeyUpdatePublicKey = secp256k1.keyUtils.publicKeyHexFromPrivateKeyHex(derivedKeyUpdatePrivateKeyHex)

  const recoverykeyPair = await secp256k1.Secp256k1KeyPair.from({
    privateKeyHex: derivedKeyRecoveryPrivateKeyHex,
    publicKeyHex: derivedKeyRecoveryPublicKey,
  })

  const recoverykeyPairJWK = await recoverykeyPair.toJsonWebKeyPair(true)

  const updatekeyPair = await secp256k1.Secp256k1KeyPair.from({
    privateKeyHex: derivedKeyUpdatePrivateKeyHex,
    publicKeyHex: derivedKeyUpdatePublicKey,
  })
  const updatekeyPairJWK = await updatekeyPair.toJsonWebKeyPair(true)

  const zippieProfileService = {
    id: 'zippieProfileId',
    type: 'zippieProfile',
    serviceEndpoint: `permastore2:${derivedKeyUpdatePublicKey}`,
  }

  const didDocumentKeys = [
    {
      id: 'publicKeyModel1Id',
      type: 'EcdsaSecp256k1VerificationKey2019',
      publicKeyJwk: recoverykeyPairJWK.publicKeyJwk,
      purposes: ['authentication', 'keyAgreement'],
    },
  ]

  const services = [zippieProfileService]

  const document = {
    publicKeys: didDocumentKeys,
    services,
  }

  const longFormDid = ION.IonDid.createLongFormDid({
    recoveryKey: recoverykeyPairJWK.publicKeyJwk,
    updateKey: updatekeyPairJWK.publicKeyJwk,
    document,
  })

  const didCreateRequest = ION.IonRequest.createCreateRequest({
    recoveryKey: recoverykeyPairJWK.publicKeyJwk,
    updateKey: updatekeyPairJWK.publicKeyJwk,
    document,
  })

  try {
    // XXX we don't need this as long as we store the long form DIDs?
    await new ION.AnchorRequest(didCreateRequest).submit()
  } catch (err) {
    console.error('Ignored error', err)
  }

  return {
    longFormDid,
  }
}

// IPFS
export const CreateIPFS = async (privateKey, profile, token) => {
  const data = {
    data: profile,
  }

  try {
    const updatePrivateKeyHex = deriveUpdatePrivateKeyHex(privateKey)
    const response = await axios.post(
      `${API_URL}/did/upload`,
      {
        data,
      },
      {
        headers: {
          'x-access-token': token,
        },
      },
    )

    const { cid } = response.data
    console.log(cid)
    // const authkey = await crypto.randomBytes(32)
    const cidRes = await permastore.insertCID(cid, signers.secp256k1(updatePrivateKeyHex))
    console.log([cid, JSON.pretty(cidRes)])
  } catch (error) {
    console.error(error)
  }
}

export const uploadProfilePhoto = async (image, token) => {
  const formData = new FormData();
  formData.append("profile-photo", image);

  try {
    return await axios.post(
      `${API_URL}/did/upload/profile_photo`,
      formData,
      {
        headers: {
          'x-access-token': token,
        },
      },
    )
  } catch (error) {
    console.error(error)
  }
}

export const LoadDID = async (longFormDID) => {
  const response = await fetch('https://ion.dev.zippieid.com/identifiers/' + longFormDID)

  const result = await response.json()
  return result
}

export const getPermstoreToken = async (accessToken) => {
  const { waitlist } = await getMe(accessToken)
  const longDIDForm = await JSON.parse(waitlist.data).did_ion

  const didProfile = await LoadDID(longDIDForm)
  const perm = didProfile.didDocument.service[0].serviceEndpoint
  return perm.split(':')[1]
}

export const LoadProfile = async (perm) => {
  try {
    const res = await axios.get(`https://hub.textile.io/ipfs/${perm}`)
    // const jsonData = await res.json()
    return res.data.data ? res.data.data : res.data
  } catch (error) {
    console.log(error)
    return
  }
}


export const getDIDIonProfile = async (username) => {
  try {
    const {data} = await axios.post( `${API_URL}/user/get_did_ion`, {username})
    const didProfile = await LoadDID(data.did_ion)
    const perm = didProfile.didDocument.service[0].serviceEndpoint
    const cilList = await permastore.list(perm.split(':')[1])
    const cidToken = cilList[cilList.length - 1].split('.')[1]
    const { claims } = await LoadProfile(cidToken)

    const userProfileDetails = claims[0].cid
    let profileImagePath = userProfileDetails.profileImageCIDPath
      ? `https://hub.textile.io/ipfs/${userProfileDetails.profileImageCIDPath}`
      : ''
    
    return { ...userProfileDetails, imagePath: profileImagePath }
  } catch (error) {
    console.log(error)
    return
  }
}
