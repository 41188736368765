import React from 'react'
import Auth from '../../layouts/Auth'
import { Table, TableBody, TableData, TableHead, TableHeading, TableRow } from '../../components/Table'

const Staked = () => {
  return (
    <Auth title={'Staked by @zippier'}>
      <div>
        <Table>
          <TableHead>
            <TableHeading>#</TableHeading>
            <TableHeading>Feature</TableHeading>
            <TableHeading>Username</TableHeading>
            <TableHeading>Action</TableHeading>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableData>1</TableData>
              <TableData>Twitter</TableData>
              <TableData>@zippier</TableData>
              <TableData>
                <span className="text-purple-600 font-semibold">View</span>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>2</TableData>
              <TableData>Photo</TableData>
              <TableData>@zippier</TableData>
              <TableData>
                <span className="text-purple-600 font-semibold">View</span>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>3</TableData>
              <TableData>Photo</TableData>
              <TableData>@geek</TableData>
              <TableData>
                <span className="text-purple-600 font-semibold">View</span>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>4</TableData>
              <TableData>Photo</TableData>
              <TableData>@human</TableData>
              <TableData>
                <span className="text-purple-600 font-semibold">View</span>
              </TableData>
            </TableRow>
          </TableBody>
        </Table>
        <div className="text-center font-semibold text-purple-700 py-4 underline">View all</div>
      </div>
    </Auth>
  )
}

export default Staked
