import React from 'react';
import { useHistory } from 'react-router';
import { FullWidthButton } from '../components/Buttons';
import { GuestCard } from '../components/Cards';

const Start = () => {
    const history = useHistory();

    const StartAction = async () => {
        await history.push('/auth');
    };
    return (
        <GuestCard canClose={false} showLogo={false}>
            <div className="mt-8 flex flex-col space-y-4 w-full py-28">
                <div className="text-gray-500 text-center">
                    <p className="text-6xl mb-4">👋</p>
                    Welcome{' '}
                    <span className="font-semibold">@{'username'}!</span> We are
                    glad to have you onboard.
                </div>

                <div className="pt-4">
                    <FullWidthButton onClick={StartAction}>
                        Start
                    </FullWidthButton>
                </div>
            </div>
        </GuestCard>
    );
};

export default Start;
