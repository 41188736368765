import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import Guest from '../layouts/Guest'
import { ProfileCard } from '../components/Cards'
import { OutlineButton } from '../components/Buttons'
import { useDashboardContext } from '../hooks/useDashboard'

const ProfileInfo = ({ userProfile }) => {
  const history = useHistory()
  const { username } = useParams()

  return (
    <div className="w-full text-gray-800">
      {userProfile?.name && (
        <ProfileCard className="mt-8" onClick={() => history.push(`/${username}/view-name`)}>
          <span>Name: {userProfile.name}</span>{' '}
          <span className="text-purple-900">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </span>
        </ProfileCard>
      )}
      {userProfile?.twitter && (
        <ProfileCard className="mt-8" onClick={() => history.push(`/${username}/view-twitter`)}>
          <span>Twitter: {userProfile.twitter}</span>{' '}
          <span className="text-purple-900">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </span>
        </ProfileCard>
      )}
      {userProfile?.education && (
        <ProfileCard className="mt-8" onClick={() => history.push(`/${username}/`)}>
          <span>Education: {userProfile.education}</span>{' '}
          <span className="text-purple-900">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </span>
        </ProfileCard>
      )}
    </div>
  )
}

const getInitials = (fullname) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

  let initials = [...fullname.matchAll(rgx)] || []

  initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()
  return initials
}
const OwnerComponent = ({ userProfile }) => {
  const history = useHistory()
  const { username } = useParams()

  return (
    <>
      {!userProfile && (
        <div className="flex flex-col justify-center items-center">
          <div className="bg-gray-50 mt-8 rounded-lg flex flex-col space-y-8 items-center justify-center px-8 py-12 text-center min-h-96 flex-1">
            <p className="text-gray-500">Get your information verified by your friends and 3rd parties. </p>
            <OutlineButton onClick={() => history.push('/select')}>Add Features</OutlineButton>
          </div>
        </div>
      )}

      {userProfile && (
        <div className="mt-8 px-8 text-center">
          <p className="mb-8">Activate your profile by staking $ZIPT. Start by clicking the button below.</p>
          <OutlineButton onClick={() => history.push(`/${username}/stake`)}>Stake $ZIPT</OutlineButton>
        </div>
      )}
    </>
  )
}

const ProfileImage = ({ imagePath, nameInitials, userProfile, isMe }) => {
  const history = useHistory()
  const { username } = useParams()

  return (
    <div className="flex items-center justify-between mb-1 w-full">
      <div className="flex items-center space-x-2">
        {imagePath ? (
          <img
            src={imagePath}
            alt="Master"
            className="h-16 w-16 rounded-full mb-2"
            onClick={() => history.push(`/${username}/view-photo`)}
          />
        ) : (
          <div className="bg-purple-100 w-24 h-24 rounded-full flex items-center justify-center">
            <span className="font-semibold text-purple-800 text-4xl">{nameInitials}</span>
          </div>
        )}
        <span className="text-purple-800 text-sm font-semibold mb-2 -ml-16">View more</span>
      </div>
      <div className="flex flex-col space-y-2">
        <OutlineButton className="text-sm" onClick={() => alert('View Stakes')}>
          View Stakes
        </OutlineButton>
        {userProfile && Object.keys(userProfile).length < 6 && isMe && (
          <OutlineButton className="text-sm" onClick={() => history.push('/select')}>Add Features</OutlineButton>
        )}
      </div>
    </div>
  )
}

const GuestProfile = () => {
  const { username } = useParams()
  const [userProfile, setUserProfile] = useState()
  const [isMe, setIsMe] = useState(false)
  const { getUserProfile, userAccepted, fetchUserInfo } = useDashboardContext()
  JSON.pretty = (json) => JSON.stringify(json, null, 2)
  const isUserLoggedIn = localStorage.getItem('isUserLoggedIn') === 'true'

  const fetchData = async () => {
    setUserProfile()
    setIsMe(false)

    const savedUserName = localStorage.getItem('username')
    const isMe = isUserLoggedIn && savedUserName === username
    // if(isUserLoggedIn && isMe && !userAccepted) {
    //     history.push('/reserved')
    // }
    const data = isMe ? await getUserProfile() : await fetchUserInfo(username)
    console.error(data)
    setUserProfile(data)
    setIsMe(isMe)
  }
  useEffect(() => {
    fetchData()
  }, [username])

  const userInfo = localStorage.getItem('userInformation')
  const fullName = userInfo ? getInitials(userProfile?.name || JSON.parse(userInfo).fullname) : 'AA'
  return (
    <Guest title={`@${username}`}>
      <div className="flex flex-col items-center justify-center bg-white">
        <ProfileImage
          nameInitials={fullName}
          imagePath={userProfile?.imagePath}
          userProfile={userProfile}
          isMe={isMe}
        />
        <ProfileInfo userProfile={userProfile} />
        {isMe && <OwnerComponent userProfile={userProfile} />}
        {!isMe && !userProfile && (
          <div className="text-center bg-gray-100 rounded-xl px-4 py-12 w-full">
            <p style={{ fontSize: '12px' }}>User is not activated yet</p>
          </div>
        )}
      </div>
    </Guest>
  )
}

export default GuestProfile
