import React from 'react'
import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import logo from '../images/zippie-logo.png'
import { DataContext } from './DataContext'
import { FullWidthButton } from '../components/Buttons'
import { BasicInput } from '../components/Inputs'
import Layout from './Layout'
import { useZippieIdContext } from '../hooks/useZippieId'
import { ErrorMessage, SuccessMessage } from '../components/Messages'
import Loading from '../components/Loading'
import { checkUsername } from '../helpers/waitList'
import { useDashboardContext } from '../hooks/useDashboard'

const Username = () => {
  const history = useHistory()

  const { isLoggedIn } = useZippieIdContext()
  const { signup } = useDashboardContext()
  const [username, setUsername] = useState('')
  const [loading, setLoading] = useState(false)
  const [{ success, message }, setResponse] = useState({
    success: false,
    message: '',
  })

  const getUsername = (e) => {
    setUsername(e.target.value)
  }

  const submitUsername = async () => {
    if (isLoggedIn) {
      history.replace('/profile')
    }

    if (username === '') {
      setResponse({
        success: false,
        message: 'please provide username',
      })
      return
    }

    setResponse({
      success: false,
      message: '',
    })
    setLoading(true)

    const { available } = await checkUsername(username)
    if (available) {
      const usernameResponse = await signup(username)
      // user already has an account and did a sign in/ we should display an welcome screen here 
      if (!usernameResponse) {
        setResponse({
          success: false,
          message: '',
        })
        return
      }
      if (usernameResponse.status === 422) {
        setLoading(false)
        setResponse({
          success: false,
          message: usernameResponse.data.error[0].msg,
        })
        console.log(usernameResponse)
        return
      }

      if (usernameResponse.status === 'Ok') {
        localStorage.setItem('username', username)

        history.replace('/reserved')
      }
    } else {
      setResponse({
        success: false,
        message: 'username is already taken',
      })
    }
  }

  return loading ? (
    <Loading />
  ) : (
    <Layout>
      <div className="mb-10 flex justify-between items-center">
        <img src={logo} className="h-6" alt="Zippie ID" />

        <span className="text-2xl">😎</span>
      </div>
      <div className="-mt-4">
        <h1 className="text-xl font-bold text-center">Reserve your Username</h1>

        <div className="text-gray-500 mt-4 tracking-wide">
          <BasicInput value={username} placeholder="username" onChange={getUsername}>
            Pick a username
          </BasicInput>
        </div>

        {message && !success && <ErrorMessage message={message} />}
        {message && success && <SuccessMessage message={message} />}

        <div className="px-2 py-4">
          <FullWidthButton onClick={submitUsername}>Next</FullWidthButton>
        </div>
      </div>
    </Layout>
  )
}

export default Username
