import React from 'react'
import Auth from '../../layouts/Auth'
import { Table, TableBody, TableData, TableHead, TableHeading, TableRow } from '../../components/Table'

const MyStakers = () => {
  return (
    <Auth title={'@zippier'}>
      <div>
        <p className="mb-3">Twitter</p>
        <p className="font-semibold mb-3">@Zippier</p>
      </div>
      <div>
        <div className="text-purple-800 my-2">
          <span>Total staked: </span>
          <span className="font-semibold">252,989 $ZIPT</span>
        </div>
        <Table>
          <TableHead>
            <TableHeading>#</TableHeading>
            <TableHeading>Stakers</TableHeading>
            <TableHeading>At stake ($ZIPT)</TableHeading>
            <TableHeading>USD Equivalent</TableHeading>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableData>1</TableData>
              <TableData>@pid</TableData>
              <TableData>50,000</TableData>
              <TableData>25</TableData>
            </TableRow>
            <TableRow>
              <TableData>2</TableData>
              <TableData>@state_space</TableData>
              <TableData>21,898</TableData>
              <TableData>12</TableData>
            </TableRow>
            <TableRow>
              <TableData>3</TableData>
              <TableData>@root_locus</TableData>
              <TableData>45,870</TableData>
              <TableData>22</TableData>
            </TableRow>
            <TableRow>
              <TableData>4</TableData>
              <TableData>@formula_one</TableData>
              <TableData>129,900</TableData>
              <TableData>56</TableData>
            </TableRow>
          </TableBody>
        </Table>
        <div className="text-center font-semibold text-purple-700 py-4 underline">View all</div>
      </div>
    </Auth>
  )
}

export default MyStakers
