import React from 'react';

export const BasicInput = ({
    type = 'text',
    value,
    id,
    placeholder = '',
    onChange,
    children,
}) => {
    return (
        <div className="flex flex-col space-y-2">
            <span className="text-gray-800 ml-2 text-sm">{children}</span>
            <input
                type={type}
                id={id}
                value={value}
                className="w-full text-sm text-gray-700 rounded-full border-gray-300 focus:border-purple-800 px-6 py-2"
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    );
};

export const NumberInput = ({
    step = 0.01,
    value,
    placeholder = '',
    onChange,
    children,
}) => {
    return (
        <div className="flex flex-col space-y-2">
            <span className="text-gray-800 ml-2 text-sm">{children}</span>
            <input
                type="number"
                step={step}
                value={value}
                className="w-full text-sm text-gray-700 rounded-full border-gray-300 focus:border-purple-800 px-6 py-2"
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    );
};

export const OtpInput = ({ otp, handleChange }) => {
    return (
        <div className="flex flex-row mb-4 justify-center items-center">
            {otp.map((data, index) => {
                return (
                    <input
                        className="mx-0.5 my-2 rounded-full w-10 h-10 sm:w-12 sm:h-12 text-center text-xl"
                        type="text"
                        name="otp"
                        maxLength="1"
                        required
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                    />
                );
            })}
        </div>
    );
};
