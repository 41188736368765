import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { getToken, signupToken, signinToken } from '../helpers/signatures'
import { Button } from '../components/Buttons'
import { CreateIPFS } from '../helpers/DIDs'
import { useZippieIdContext } from '../hooks/useZippieId'

const Debugger = () => {
  const history = useHistory()
  const isUserLoggedIn =
    localStorage.getItem('isUserLoggedIn') === 'true' ? true : false
  const { getSecp256k1 } = useZippieIdContext()

  const ipfs = async () => {
    const secp = await getSecp256k1()
    const userInformation = await JSON.parse(
      localStorage.getItem('userInformation')
    )
    const privateKey = await JSON.parse(localStorage.getItem('privateKey'))

    // generate tokens
    const token = await getToken(privateKey, secp, userInformation)
    const username = localStorage.getItem('username')
    const profile = {
      claims: [{ type: 'profile_data', cid: { username, token } }],
    }
    console.log(profile)
    const res = await CreateIPFS(privateKey.privkey, profile, token)
    console.log(res)
  }

  const myToken = async () => {
    const userInformation = await JSON.parse(localStorage.getItem('userInformation'))

    const secp = await getSecp256k1()
    const privateKey = await JSON.parse(localStorage.getItem('privateKey'))

    const response = await signupToken(
      secp.sender.pubkey,
      userInformation.fullname,
      userInformation.email,
      privateKey,
      secp.secp256k1, // secp
    )

    console.log(response);

    // generate tokens
    // const token = await getToken(privateKey, secp, userInformation)
  }

  useEffect(() => {
    if (!isUserLoggedIn) {
      history.replace('/')
    }
  })

  return (
    <div className="flex min-h-screen w-full items-center justify-center space-x-2">
      {isUserLoggedIn && <Button onClick={myToken}>myToken</Button>}
      <Button onClick={() => history.replace('/')}>{isUserLoggedIn ? 'Go to Home Page' : 'Sign In/Up'}</Button>
    </div>
  )
}

export default Debugger
