import React from 'react'
import { useHistory } from 'react-router-dom'
import Auth from '../../layouts/Auth'
import { OutlineButton } from '../../components/Buttons'
import { Table, TableBody, TableData, TableHead, TableHeading, TableRow } from '../../components/Table'

const Stake = () => {
  const history = useHistory()
  return (
    <Auth title={'@zippier'}>
      <div className="flex justify-between items-center mb-6">
        <div>
          <p className="mb-3">Twitter</p>
          <p className="font-semibold mb-3">@Zippier</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="mb-3 text-purple-600 underline font-semibold" onClick={() => history.push('/ui/report')}>
            Report Claim
          </p>
          <OutlineButton className="text-sm" onClick={() => alert('Stake Twitter')}>
            Stake Twittwer
          </OutlineButton>
        </div>
      </div>
      <div>
        <div className="text-purple-800 my-2">
          <span>Total staked: </span>
          <span className="font-semibold">252,989 $ZIPT</span>
        </div>
        <Table>
          <TableHead>
            <TableHeading>#</TableHeading>
            <TableHeading>Stakers</TableHeading>
            <TableHeading>At stake ($ZIPT)</TableHeading>
            <TableHeading>USD equivalent</TableHeading>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableData>1</TableData>
              <TableData>@pid</TableData>
              <TableData>33,500</TableData>
              <TableData>47</TableData>
            </TableRow>
            <TableRow>
              <TableData>2</TableData>
              <TableData>@controller</TableData>
              <TableData>29,098</TableData>
              <TableData>41</TableData>
            </TableRow>
            <TableRow>
              <TableData>3</TableData>
              <TableData>@regulator</TableData>
              <TableData>67,898</TableData>
              <TableData>92</TableData>
            </TableRow>
            <TableRow>
              <TableData>4</TableData>
              <TableData>@switch</TableData>
              <TableData>127,900</TableData>
              <TableData>129</TableData>
            </TableRow>
          </TableBody>
        </Table>
        <div className="text-center font-semibold text-purple-700 py-4 underline">View all</div>
      </div>
    </Auth>
  )
}

export default Stake
