/* eslint-disable import/no-anonymous-default-export */

import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { VaultProvider } from './hooks/useVault'
import { ZippieIdProvider } from './hooks/useZippieId'
import { DashboardProvider, useDashboardContext } from './hooks/useDashboard'
import Intro from './waitlist/Intro'
import ZippieAuth from './views/ZippieAuth'
import { DataProvider } from './waitlist/DataContext'
import Reserved from './waitlist/Reserved'
import Verify from './waitlist/Verify'
import Username from './waitlist/Username'
import Signin from './waitlist/Signin'
import Start from './views/Start'
import SelectFeatures from './views/SelectFeatures'
import FillFeatures from './views/FillFeatures'
import InviteCode from './waitlist/InviteCode'
import Home from './views/Home'
import Stake from './views/Stake'
import WalletAddress from './views/WalletAddress'
import ViewProfile from './views/ViewProfile'
import ViewPhoto from './views/ViewPhoto'
import ViewName from './views/ViewName'
import ViewTwitter from './views/ViewTwitter'
import GuestProfile from './views/GuestProfile'
import Debugger from './debug/debugger'

import MyStakes from './views/mock/MyStakes'
import MyStakers from './views/mock/MyStakers'
import Profile from './views/mock/Profile'
import Staked from './views/mock/Staked'
import StakeUI from './views/mock/Stake'
import Unstake from './views/mock/Unstake'
import Report from './views/mock/Report'

import AdminHome from './views/admin/index'
import AdminWaitlist from './views/admin/waitlist'
import AdminWaitlistSingle from './views/admin/waitlistSingle'

const App = () => {
 

  const AdminOnlyRoute = ({ component: Component, ...rest }) => {   
    const { authorities, accessToken } = useDashboardContext()
    const isAdmin =  authorities.find((role) => role === 'ROLE_ADMIN') !== undefined;
  
    return accessToken ? (
      <Route {...rest} render={props => (
        isAdmin ? (
          <Component {...props}/>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location }}}/>
        )
      )}/>
    ) : null
  }

  return (
    <DataProvider>
      <Switch>
        <Route exact path="/auth" render={(props) => <ZippieAuth {...props} />} />
        <Route exact path="/" render={(props) => <Intro {...props} />} />
        <Route exact path="/invite_code" render={(props) => <InviteCode {...props} />} />
        <Route exact path="/username" render={(props) => <Username {...props} />} />
        <Route exact path="/signin" render={(props) => <Signin {...props} />} />
        <Route exact path="/verify" render={(props) => <Verify {...props} />} />
        <Route exact path="/reserved" render={(props) => <Reserved {...props} />} />
        <Route exact path="/start" render={(props) => <Start {...props} />} />
        <Route exact path="/select" render={(props) => <SelectFeatures {...props} />} />
        <Route exact path="/fill" render={(props) => <FillFeatures {...props} />} />
       
      
        <Route exact path="/debug" render={(props) => <Debugger {...props} />} />

        <Route exact path="/ui/my_stakes" render={(props) => <MyStakes {...props} />} />
        <Route exact path="/ui/my_stakers" render={(props) => <MyStakers {...props} />} />
        <Route exact path="/ui/profile" render={(props) => <Profile {...props} />} />
        <Route exact path="/ui/staked" render={(props) => <Staked {...props} />} />
        <Route exact path="/ui/stake" render={(props) => <StakeUI {...props} />} />
        <Route exact path="/ui/unstake" render={(props) => <Unstake {...props} />} />
        <Route exact path="/ui/report" render={(props) => <Report {...props} />} />

        <AdminOnlyRoute exact path="/admin" component={AdminHome} />
        <AdminOnlyRoute exact path="/admin/waitlist" component={AdminWaitlist} />
        <AdminOnlyRoute exact path="/admin/waitlist/:username" component={AdminWaitlistSingle} />
        
        <Route exact path="/:username/" render={(props) => <GuestProfile {...props} />} />
        <Route exact path="/:username/view_profile" render={(props) => <ViewProfile {...props} />} />
        <Route exact path="/:username/view-photo" render={(props) => <ViewPhoto {...props} />} />
        <Route exact path="/:username/view-name" render={(props) => <ViewName {...props} />} />
        <Route exact path="/:username/view-twitter" render={(props) => <ViewTwitter {...props} />} />
        <Route exact path="/:username/stake" render={(props) => <Stake {...props} />} />
        <Route exact path="/:username/wallet_address" render={(props) => <WalletAddress {...props} />} />
        <Route exact path="/:username/home" render={(props) => <Home {...props} />} />

      </Switch>
    </DataProvider>
  )
}

export default ({ contentParams }) => (
  <VaultProvider contentParams={contentParams}>
    <ZippieIdProvider>
      <Router>
        <DashboardProvider>
          <App contentParams={contentParams} />
        </DashboardProvider>
      </Router>
    </ZippieIdProvider>
  </VaultProvider>
)
