import React, { useState, useContext,useEffect } from "react";
import { useHistory } from "react-router";
import { Button } from "../components/Buttons";
import { FeatureCard } from "../components/Cards";
import { useDashboardContext } from "../hooks/useDashboard";
import Auth from "../layouts/Auth";
import { DataContext } from "../waitlist/DataContext";

const SelectFeatures = () => {
    const history = useHistory();
    const [userProfile, setUserProfile] = useState()
    const {getUserProfile} = useDashboardContext()
    const fetchUserInfo = async () => {
        // add loading 
        const data = await getUserProfile()
        setUserProfile(data)
      }
    useEffect(() => {
        fetchUserInfo()
    },  [])
    const featuresData = [
        {
            name: "Name",
            label: "Your full name",
            id: "name",
            icon: "person-circle-outline",
            isImage: false,
        },
        {
            name: "Twitter",
            label: "Your twitter handle",
            id: "twitter",
            icon: "logo-twitter",
            isImage: false,
        },
        {
            name: "Profile",
            label: "Your profile photo",
            id: "imagePath",
            icon: "camera-outline",
            isImage: true,
        },
        {
            name: "Education",
            label: "Your education background",
            id: "education",
            icon: "school-outline",
            isImage: false,
        },
    ];
    const [featuresSelected, setFeaturesSelected] = useState([]);
    const [features, setFeatures] = useContext(DataContext);

    const selectFeature = (event) => {
        // event.preventDefault();
        if (event.target.checked) {
            setFeaturesSelected([...featuresSelected, event.target.id]);
        } else {
            setFeaturesSelected(
                featuresSelected.filter((claim) => claim !== event.target.id)
            );
        }
    };

    const SaveFeatures = async () => {
        localStorage.removeItem("features");
        // save claims here
        const tfs = featuresData.filter(({ id }) =>
            featuresSelected.includes(id)
        );
        await setFeatures(tfs);
        localStorage.setItem("features", JSON.stringify(tfs));
        history.push("/fill");
    };

    return (
        <Auth showBackArrow={true} showPhoto={false} title={"Add Features"}>
            <div className="mt-4 px-3 py-6 bg-gray-100 rounded-lg w-full min-h-8">
                <div className="text-center font-semibold">Select Features</div>
                <div className="text-center">
                    <div className="my-4 grid grid-cols-2 gap-4">
                        {featuresData.map((data) => {
                            const isDisabled = userProfile && Object.keys(userProfile).find((key) => key === data.id) ;
                            return (
                            <div key={data.id}>
                                <label htmlFor={data.id}>
                                    <FeatureCard
                                        isSelected={featuresSelected.includes(
                                            data.id
                                        )}
                                        isDisabled={isDisabled}
                                    >
                                        <div className="py-1 text-2xl">
                                            <ion-icon
                                                name={data.icon}
                                            ></ion-icon>
                                        </div>
                                        <div className="py-1">{data.name}</div>
                                    </FeatureCard>
                                </label>
                                <input
                                    type="checkbox"
                                    name={data.id}
                                    id={data.id}
                                    className="hidden"
                                    onChange={(ev) =>  !isDisabled ? selectFeature(ev) : null}
                                    disabled={isDisabled}
                                    checked={featuresSelected.includes(data.id)}
                                />
                            </div>
                        )})}
                    </div>

                    <div className="my-8">
                        <Button
                            onClick={SaveFeatures}
                            disabled={featuresSelected.length < 1}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Auth>
    );
};

export default SelectFeatures;
