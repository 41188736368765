/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getDIDIonProfile } from '../helpers/DIDs'

import { useVaultContext } from './useVault'
import { useZippieIdContext } from './useZippieId'
import { signupToken, signinToken } from '../helpers/signatures'
import { CreateDID, LoadDID } from '../helpers/DIDs'
import { reserveUsername, getMe } from '../helpers/waitList'
import { clearLocalStorage } from '../helpers/auth'

export const useDashboard = () => {
  const history = useHistory()
  const location = useLocation()

  const { vaultIsReady } = useVaultContext()
  const { isLoggedIn, isZippieIdReady, signUpWithZippie, getSecp256k1, userInformation } = useZippieIdContext()

  const [pubKey, setPubKey] = useState('')
  const [privKey, setPrivKey] = useState('')
  const [authorities, setAuthorities] = useState([])
  const [userEmail, setUserEmail] = useState('')
  const [userAccepted, setUserAccepted] = useState(false)

  const [isProcessing, setIsProcessing] = useState(false)

  const [accessToken, setAccessToken] = useState('')
  const [isDashboardReady, setIsDashboardReady] = useState(false)
  const [userProfile, setUserProfile] = useState()

  useEffect(() => {
    if (vaultIsReady && isZippieIdReady) {
      setIsDashboardReady(true)
    }
  }, [vaultIsReady, isZippieIdReady])

  useEffect(async () => {
    if (isDashboardReady && isLoggedIn) {
      const keys = await getSecp256k1()
      setPubKey(keys.sender.pubkey)
      setPrivKey(keys.privateKey.privKey)
    }
  })

  const signup = async (username) => {
    try {
      setIsProcessing(true)
      const res = await signUpWithZippie('signUp');
      // disabled for now, we need a way to handle already existing users 
      // if(res.type !== 'signup') {
      //   signIn(res)
      //   return;
      // }
      if (res && res.success) {
        const userInformation = await JSON.parse(localStorage.getItem('userInformation'))
        const emailAddr = userInformation?.email
        const secp = await getSecp256k1()
        // generate tokens
        const {data} = await signupToken(
          secp.sender.pubkey,
          userInformation.fullname,
          userInformation.email,
          secp.privateKey,
          secp.secp256k1, // secp
        )
        const { token, email, name, authorities } = data

        const { longFormDid } = await CreateDID(secp.privateKey.privkey)
        const usernameResponse = await reserveUsername(secp.sender.pubkey, username, emailAddr, token, longFormDid)
        const res = await getMe(token)

        if (res.status === 'Ok') {
          localStorage.setItem('username', res.waitlist.username)
          const me_data = JSON.parse(res.waitlist.data)
          console.log(await LoadDID(me_data.did_ion))
        } 
        setAuthorities(authorities)
        setUserEmail(email)
        setAccessToken(token)

        return usernameResponse
      }
    } catch (e) {
      console.error(e)
    }
  }
  const fetchWaitlist =  async () => {
    const res = await getMe(accessToken)
      if (res.waitlist?.status?.toLowerCase() === 'accepted') {
        setUserAccepted(true)
      }
  }
  const signIn = async (data) => {
    const res = data || await signUpWithZippie('signIn')
    
    if (res && res.success) {
      const secp = await getSecp256k1()
      const privateKey = await JSON.parse(localStorage.getItem('privateKey'))

      // generate tokens
      const { data } = await signinToken(secp.sender.pubkey, userInformation.email, privateKey, secp.secp256k1)
      const { token, email, authorities } = data
      const res = await getMe(data.token)
      if (res.waitlist?.status?.toLowerCase() === 'accepted') {
        setUserAccepted(true)
      }
      setAuthorities(authorities)
      setUserEmail(email)
      setAccessToken(token)

      if (res.status === 'Ok') {
        localStorage.setItem('username', res.waitlist.username)
        // history.push('/')
      } else {
        console.log(res)
        clearLocalStorage()
      }
    }
  }

  const fetchUserInfo = async (username) => {
    const savedUserName = username || localStorage.getItem('username')
    const data = await getDIDIonProfile(savedUserName)
    return data;
  }
  const getUserProfile = async() => {
    const userData = userProfile ? userProfile : await fetchUserInfo()
    setUserProfile(userData)

    return userData
  }

  useEffect(() => {
    if (isLoggedIn && isZippieIdReady && vaultIsReady && !isProcessing) {
      signIn()
    }
  }, [isLoggedIn, isZippieIdReady, vaultIsReady, isProcessing])
  return {
    pubKey,
    isDashboardReady,
    signup,
    accessToken,
    signIn,
    authorities,
    userAccepted,
    fetchUserInfo,
    getUserProfile,
    fetchWaitlist,
    setUserProfile
  }
}

const [DashboardProvider, useDashboardContext] = constate(useDashboard)

export { DashboardProvider, useDashboardContext }
