import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const getHashValue = (key) => {
    var matches = window.location.hash.match(new RegExp(key + '=([^&]*)'));
    return matches ? matches[1] : null;
};

const isWelcome = getHashValue('profile');
const isSignUp = getHashValue('signup');
const isSignIn = getHashValue('signin');
const paymentHash = getHashValue('paymentHash');

const contentParams = {
    isWelcome: isWelcome ? true : false,
    isSignUp: isSignUp ? true : false,
    isSignIn: isSignIn ? true : false,
    paymentHash,
};

ReactDOM.render(
    <React.StrictMode>
        <App contentParams={contentParams} />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
