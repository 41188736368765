import React from 'react'

const Waitlist = () => {
  return (
    <>
      <h1>Admin Waitlist Dashboard - Single</h1>
    </>
  )
}

export default Waitlist
