import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDashboardContext } from '../hooks/useDashboard'
import { useZippieIdContext } from '../hooks/useZippieId'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { getUsername } from '../helpers/waitList'
import { useVaultContext } from '../hooks/useVault'

const Auth = ({ showBackArrow = true, showZIPT = true, showPhoto = false, title = '', children, imagePath }) => {
  const history = useHistory()
  const { isDashboardReady, accessToken, userAccepted } = useDashboardContext()
  const {vaultIsReady} = useVaultContext()
  const { isLoggedIn, isZippieIdReady } = useZippieIdContext()

  const [doneLoading, setDoneLoading] = useState(false)

  useEffect(() => {
    if(!isDashboardReady) {
      return;
    }

    if (!isLoggedIn) {
      history.replace('/')
      return
    }
  
    if(!accessToken) {
      return
    }
    
    const hasLoaded = async () => {
      if (!userAccepted) {
        history.replace('/reserved')
      }
      setDoneLoading(true)
    }
    hasLoaded()
  }, [isDashboardReady, isLoggedIn, accessToken, userAccepted])

  return isDashboardReady && doneLoading ? (
    <div className="text-gray-700 flex flex-col h-screen pb-6">
      <Navbar />
      <div className="grid grid-cols-12 mx-4 md:mx-0 md:mr-8 h-full">
        <div className="hidden md:grid md:col-span-2 lg:col-span-1">
          <Sidebar />
        </div>
        <div className="col-span-12 md:col-span-10 lg:col-span-11 bg-white rounded-3xl pb-12 mt-4 md:mt-8 mr-0 md:mr-16">
          <div className="relative bg-purple-800 h-28 rounded-t-3xl pl-4">
            {showBackArrow && (
              <div className="absolute top-0 left-4 text-white text-sm font-light mt-4 ml-3">
                <button onClick={() => history.goBack()} className="focus:outline-none">
                  <ion-icon name="arrow-back"></ion-icon>
                  <span className="ml-2">Go back</span>
                </button>
              </div>
            )}

            {showZIPT && (
              <div className="absolute top-0 right-4 text-white text-sm font-light mt-4 ml-3 underline">
                <button onClick={() => alert('Get ZIPT')}>
                  <span className="ml-2">Get $ZIPT</span>
                </button>
              </div>
            )}

            {!showPhoto && (
              <div className="grid absolute left-4 md:left-8 bottom-2 md:bottom-4 text-sm text-right text-white font-semibold ml-3">
                {title}
              </div>
            )}
          </div>

          {showPhoto && (
            <div className="relative flex justify-between">
              <div className="absolute flex justify-center items-center left-3 md:left-8 -top-9 md:-top-12 bg-gray-100 h-16 md:h-24 w-16 md:w-24 rounded-full text-purple-800 font-semibold text-2xl md:text-4xl">
                <img
                  className="h-16 md:h-24 w-16 md:w-24 rounded-full"
                  src={
                    imagePath
                      ? imagePath
                      : 'https://cdn.geekwire.com/wp-content/uploads/2012/02/nerd-bigstock_Extreme_Computer_Nerd_1520708.jpg'
                  }
                  alt=""
                />
              </div>
            </div>
          )}

          <div className="mt-8 md:mt-12 mx-4 md:mx-12 h-full">{children}</div>
        </div>
      </div>
    </div>
  ) : null
}

export default Auth
