export const MY_CREDENTIALS_SERVICE_APP = {
  local: '/permastore2/038d6e4409248992db03aed2c100b520f882d853b36560025aa5c50d2a709cc752',
  development: '/ens/zippie/mycredentials-dev.zippie',
  testing: '/ens/zippie/mycredentials-testing.zippie',
  release: '/ens/zippie/mycredentials.zippie',
}

export const ZIPPIE_ID_APP = {
  development: '/ens/zippie/id-dev.zippie',
  testing: '/ens/zippie/id-testing.zippie',
  release: '/ens/zippie/id.zippie',
}

export const ZIPPIE_URL = {
  development: 'https://dev.zippie.com',
  testing: 'https://testing.zippie.com',
  release: 'https://zippie.com',
}

export const DID_URL = {
  development: 'https://dev.zippieid.com/',
  testing: 'https://dev.zippieid.com/',
  release: 'https://dev.zippieid.com/',
}
