import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { OutlineButton } from '../components/Buttons'
import { useDashboardContext } from '../hooks/useDashboard'

import { Table, TableBody, TableData, TableHead, TableHeading, TableRow } from '../components/Table'
import Guest from '../layouts/Guest'

const ViewTwitter = () => {
  const history = useHistory()
  const { username } = useParams()

  const { fetchUserInfo } = useDashboardContext()
  const [userProfile, setUserProfile] = useState()

  const fetchData = async () => {
    const data = await fetchUserInfo(username)
    setUserProfile(data)
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Guest showBackArrow={true} title={`@${userProfile?.username}`}>
      <div className="flex flex-col justify-center h-full">
        <div className="flex justify-between items-center h-full">
          <div className="text-gray-500">
            <p className="text-sm mb-2">Twitter handle</p>
            <p className="font-semibold">{userProfile?.twitter}</p>
          </div>
          <div className="flex flex-col justify-between items-center space-y-3 text-sm">
            <span className="text-purple-800 font-semibold underline">Report Claim</span>
            <OutlineButton onClick={() => history.push('/stake')}>Stake Twitter</OutlineButton>
          </div>
        </div>

        <div className="w-full mt-6">
          <p className="font-semibold text-gray-500">Stakers</p>
          <div className="text-purple-600 font-semibold my-4">
            Total staked: <span className="text-purple-800">5000</span>
          </div>
          <Table>
            <TableHead>
              <TableHeading>#</TableHeading>
              <TableHeading>Stakers</TableHeading>
              <TableHeading>At stake ($ZIPT)</TableHeading>
              <TableHeading>USD Equivalent</TableHeading>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableData>1</TableData>
                <TableData>@zippier</TableData>
                <TableData>10,890</TableData>
                <TableData>19</TableData>
              </TableRow>
              <TableRow>
                <TableData>2</TableData>
                <TableData>@zippier</TableData>
                <TableData>9,876</TableData>
                <TableData>15</TableData>
              </TableRow>
              <TableRow>
                <TableData>3</TableData>
                <TableData>@geek</TableData>
                <TableData>8,700</TableData>
                <TableData>12</TableData>
              </TableRow>
              <TableRow>
                <TableData>4</TableData>
                <TableData>@human</TableData>
                <TableData>15,005</TableData>
                <TableData>22</TableData>
              </TableRow>
            </TableBody>
          </Table>
          <div className="text-center font-semibold text-purple-700 py-4 underline">View all</div>
        </div>
      </div>
    </Guest>
  )
}

export default ViewTwitter
