import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useDashboardContext } from '../hooks/useDashboard';
import { useZippieIdContext } from '../hooks/useZippieId';

import { Button } from '../components/Buttons';
import logo from '../images/zippie-logo.png';

const ZippieAuth = () => {
    const history = useHistory();
    const location = useLocation();

    const { signUpWithZippie } = useZippieIdContext();
    const { isDashboardReady } = useDashboardContext();

    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn') === 'true';

    const authWithZippie = async (type) => {
        const response = await signUpWithZippie(type);

        if (response?.success) history.replace('/profile');
    };

    useEffect(() => {
        if (location.pathname === '/auth' && isUserLoggedIn)
            history.replace('/profile');

        if (location.pathname === '/profile' && !isUserLoggedIn)
            history.replace('/auth');
    });

    return isDashboardReady ? (
        <div className="flex min-h-screen w-full justify-center items-center">
            <div className="flex flex-col bg-white max-w-md min-h-8 shadow-xl rounded-xl px-8 md:px-12 py-12 items-center">
                <div className="mb-10">
                    <img src={logo} className="h-10" alt="Zippie ID" />
                </div>

                <div className="text-2xl font-semibold mb-10 text-gray-700">
                    Authentication required
                </div>

                <div className="text-gray-500 text-center">
                    Polite interruption: to proceed to the requested page, you
                    need to be authenticated. Login or Signup to proceed.
                </div>

                <div className="my-8 flex space-x-2">
                    <Button
                        onClick={() => {
                            authWithZippie('signIn');
                        }}
                    >
                        <span className="font-semibold">Login</span>
                    </Button>
                    <Button
                        onClick={() => {
                            authWithZippie('signUp');
                        }}
                    >
                        <span className="font-semibold">Signup</span>
                    </Button>
                </div>
            </div>
        </div>
    ) : null;
};

export default ZippieAuth;
