import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/zippie-logo.png';
import { OutlineButton } from '../components/Buttons';
import MobileSidebar from './MobileSidebar';

const Navbar = () => {
    const balance = 0;

    const [showSidebar, setShowSidebar] = useState(false);

    const ToggleSidebar = () => {
        setShowSidebar((prev) => !prev);
    };

    return (
        <div className="flex w-full my-0 mx-0 py-4 px-4 sm:px-8 items-center justify-between">
            <button
                onClick={ToggleSidebar}
                className="text-gray-500 font-extrabold flex md:hidden text-4xl"
            >
                <ion-icon name="menu-outline"></ion-icon>
            </button>

            <MobileSidebar
                showSidebar={showSidebar}
                ToggleSidebar={ToggleSidebar}
            />

            <div>
                <Link to="/">
                    <img src={logo} className="h-6 sm:h-8" alt="ZippieID" />
                </Link>
            </div>
            <div className="flex space-x-4 justify-center items-center">
                <div className="text-gray-700 font-extrabold">
                    {`${balance} $ZIPT`}
                </div>

                <div className="font-bold hidden sm:flex">
                    <OutlineButton>View</OutlineButton>
                </div>

                <div className="text-purple-800 font-extrabold hidden sm:flex">
                    Share
                </div>
            </div>
        </div>
    );
};

export default Navbar;
