import React from 'react'
import { useHistory } from 'react-router-dom'
import Auth from '../../layouts/Auth'
import { OutlineButton } from '../../components/Buttons'

const Report = () => {
  const history = useHistory()
  return (
    <Auth title={'Report Photo'}>
      <div className="bg-gray-100 rounded-lg px-4 py-4 text-gray-500">
        <div className="text-center font-bold mb-4">@zippier claims that it is them on the picture</div>
        <p className="font-semibold text-sm">Some facts about the picture:</p>

        <ul className="mt-4 px-4 text-sm">
          <li className="list-decimal my-4">This claim is backed by 7 people, who have together staked 3000 ZIPT.</li>
          <li className="list-decimal my-4">Starting the dispute process costs 100 ZIPT.</li>
          <li className="list-decimal my-4">
            If the decentralized Kleros court approves your dispute, you will get back 2900 ZIPT plus your dispute
            process costs.
          </li>
          <li className="list-decimal my-4">
            If the court rejects your dispute, you will lose 100 ZIPT (the dispute process cost).
          </li>
          <li className="list-decimal my-4">The process will take 7-14 days.</li>
          <li className="list-decimal my-4">Read more about the process here (link to a separate webpage)</li>
        </ul>

        <div className="text-sm mb-8">
          <p className="font-semibold">Do you want to dispute this claim?</p>
          <div className="mt-2 flex flex-col space-y-3 text-xs">
            <label className="inline-flex items-center">
              <input type="radio" className="form-radio" name="response" value="yes" />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center">
              <input type="radio" className="form-radio" name="response" value="no" />
              <span className="ml-2">No. Get me back to @username's profile.</span>
            </label>
          </div>
        </div>
      </div>
    </Auth>
  )
}

export default Report
