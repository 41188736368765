import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { InviteCard, ShareCard } from '../components/Cards'
import { Modal } from '../components/Modals'
import { useZippieIdContext } from '../hooks/useZippieId'
import logo from '../images/zippie-logo.png'

const MobileSidebar = ({ showSidebar, ToggleSidebar, children }) => {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const { logout } = useZippieIdContext()
  const [view, setView] = useState('')

  const ToggleModal = (view = null) => {
    // alert(view);
    setView(view)
    setShowModal((prev) => !prev)
  }

  return (
    <div
      className={`bg-black bg-opacity-70 fixed h-full inset-0 justify-center items-center transition-all delay-500 duration-1000 ease-in-out z-50 ${
        showSidebar ? 'flex' : 'hidden'
      }`}
    >
      <div className="absolute bg-white left-0 top-0 min-h-screen max-w-xs overflow-y-auto">
        <div className="relative flex flex-col w-full h-full my-8 px-4 items-start justify-between space-y-10">
          <div className="flex justify-between items-center space-x-8">
            <Link to="/">
              <img src={logo} className="h-5 sm:h-8" alt="ZippieID" />
            </Link>
            <button className="text-xl text-gray-500" onClick={ToggleSidebar}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
          </div>

          <Link to="/">
            <div className="flex space-x-2 items-center text-gray-700 hover:text-purple-800">
              <span className="text-2xl">
                <ion-icon name="home-outline"></ion-icon>
              </span>
              <span>Home</span>
            </div>
          </Link>

          <button onClick={() => ToggleModal('share')}>
            <div className="flex space-x-2 items-center text-gray-700 hover:text-purple-800">
              <span className="text-2xl">
                <ion-icon name="share-social-outline"></ion-icon>
              </span>
              <span>Share</span>
            </div>
          </button>

          <button onClick={() => ToggleModal('invites')}>
            <div className="flex space-x-2 items-center text-gray-700 hover:text-purple-800">
              <span className="text-2xl">
                <ion-icon name="add-circle-outline"></ion-icon>
              </span>
              <span>Invites</span>
            </div>
          </button>

          <Link to="/">
            <div className="flex space-x-2 items-center text-gray-700 hover:text-purple-800">
              <span className="text-2xl">
                <ion-icon name="notifications-outline"></ion-icon>
              </span>
              <span>Notifications</span>
            </div>
          </Link>

          <button onClick={() => history.push('/view_profile')}>
            <div className="flex space-x-2 items-center text-gray-700 hover:text-purple-800">
              <span className="text-2xl">
                <ion-icon name="person-circle-outline"></ion-icon>
              </span>
              <span>Profile</span>
            </div>
          </button>

          <Link to="/">
            <div className="flex space-x-2 items-center text-gray-700 hover:text-purple-800">
              <span className="text-2xl">
                <ion-icon name="settings-outline"></ion-icon>
              </span>
              <span>Settings</span>
            </div>
          </Link>

          <button onClick={logout}>
            <div className="flex space-x-2 items-center text-gray-700 hover:text-purple-800">
              <span className="text-2xl">
                <ion-icon name="log-out-outline"></ion-icon>
              </span>
              <span>Logout</span>
            </div>
          </button>
        </div>
        {children}
      </div>

      <Modal showModal={showModal} toggleModal={ToggleModal}>
        {view === 'share' && (
          <div className="bg-white rounded-lg grid grid-cols-1 divide-y divide-gray-300">
            <div className="font-bold text-center pb-2">Share Profile</div>
            <div>
              <ShareCard>
                <span className="text-2xl font-semibold">🐢</span>
                <span>Share with WhatsApp</span>
              </ShareCard>

              <ShareCard>
                <span className="text-2xl font-semibold">🐢</span>
                <span>Share with Twitter</span>
              </ShareCard>

              <ShareCard>
                <span className="text-2xl font-semibold">🐢</span>
                <span>Copy Link</span>
              </ShareCard>
            </div>
          </div>
        )}
        {view === 'invites' && (
          <div className="bg-white rounded-lg grid grid-cols-1 divide-y divide-gray-300">
            <div className="font-bold text-center pb-2">You’ve got 5 invites</div>
            <div>
              <div className="my-4">
                <p className="text-xs">Here are 5 special codes so your friends can skip the waitlist.</p>
              </div>
              <InviteCard>
                <span className="text-md font-semibold">🤞 GHY78</span>
                <span>Copy</span>
              </InviteCard>

              <InviteCard>
                <span className="text-md font-semibold">🤞 GHY78</span>
                <span>Copy</span>
              </InviteCard>

              <InviteCard>
                <span className="text-md font-semibold">🤞 GHY78</span>
                <span>Copy</span>
              </InviteCard>

              <InviteCard>
                <span className="text-md font-semibold">🤞 GHY78</span>
                <span>Copy</span>
              </InviteCard>

              <InviteCard>
                <span className="text-md font-semibold">🤞 GHY78</span>
                <span>Copy</span>
              </InviteCard>
              <div className="my-4">
                <p className="text-xs">
                  Tell your friends to go to Zippie ID and hit “I have an invite code” to redeem their invite.
                </p>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default MobileSidebar
