let API_URL;
const HOST_URL = window.location.hostname;

switch (HOST_URL) {
    case 'localhost':
    case '127.0.0.1':
        API_URL = 'http://localhost:7777/api'; // 'https://backend.dev.zid.is/api';
        break;
    case 'dev.zid.is':
        API_URL = 'https://backend.dev.zid.is/api';
        break;
    case 'test.zid.is':
        API_URL = 'https://backend.test.zid.is/api';
        break;
    default:
        API_URL = 'https://backend.zid.is/api';
}

export { API_URL, HOST_URL };
