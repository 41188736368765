import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../images/zippie-logo.png';
import { DataContext } from './DataContext';

import { FullWidthButton } from '../components/Buttons';
import { BasicInput } from '../components/Inputs';
import Layout from './Layout';

const InviteCode = () => {
    const [inviteCode, setInviteCode] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [userdata, setUserdata] = useContext(DataContext);
    const history = useHistory();

    const getInviteCode = (e) => {
        setInviteCode(e.target.value);
    };

    const getUsername = (e) => {
        const nameStr = e.target.value;
        setUsername(`${nameStr.startsWith('@') ? '' : '@'}${nameStr}`);
    };

    const getEmail = (e) => {
        setEmail(e.target.value);
    };

    const SubmitUsername = async () => {
        await setUserdata({
            username: username,
            email: email,
            invite_code: inviteCode,
        });
        await console.log(userdata); // do more meaningful with this data :)
        await history.push('/verify');
    };

    return (
        <Layout>
            <div className="mb-10 flex justify-between items-center">
                <img src={logo} className="h-6" alt="Zippie ID" />

                <span className="text-2xl">😎</span>
            </div>
            <div className="-mt-4">
                <h1 className="text-xl font-bold text-center">
                    Use Invite Code
                </h1>

                <div className="text-gray-500 mt-4 tracking-wide">
                    <BasicInput
                        value={inviteCode}
                        placeholder="Enter your invite code"
                        onChange={getInviteCode}
                    >
                        Invite code
                    </BasicInput>
                </div>

                <div className="text-gray-500 mt-4 tracking-wide">
                    <BasicInput
                        value={username}
                        placeholder="@username"
                        onChange={getUsername}
                    >
                        Pick a username
                    </BasicInput>
                </div>

                <div className="text-gray-500 mt-4 tracking-wide">
                    <BasicInput
                        value={email}
                        placeholder="mail@domain.host"
                        onChange={getEmail}
                    >
                        Email address
                    </BasicInput>
                </div>

                <div className="px-2 py-4">
                    <FullWidthButton onClick={SubmitUsername}>
                        Next
                    </FullWidthButton>
                </div>
            </div>
        </Layout>
    );
};

export default InviteCode;
