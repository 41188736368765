import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../components/Buttons';
import { NumberInput } from '../components/Inputs';
import Auth from '../layouts/Auth';
import { DataContext } from '../waitlist/DataContext';

const Stake = () => {
    const [amount, setAmount] = useState('');
    const [showValue, setShowValue] = useState(false);
    const [stakeAmount, setStakeAmount] = useContext(DataContext);

    const formatNumber = (x) => {
        let parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    };

    const history = useHistory();
    const ZIPTVal = 0.0042;
    const ETHVal = 24676;

    const GetAmount = (e) => {
        const rawValue = e.target.value.toString().replace('-', '');
        setAmount(parseFloat(rawValue));

        setShowValue(false);
    };

    const CalculateValue = () => {
        if (amount >= 5) {
            // let amt = parseFloat(amount).toFixed(2);
            setStakeAmount({
                ZIPT: parseFloat(amount / ZIPTVal).toFixed(0),
                ETH: parseFloat(amount / ETHVal).toFixed(6),
                USD: parseFloat(amount),
            });

            setShowValue(true);
        } else {
            alert('Please enter the amount above 5 USD');
        }
    };

    const SaveAmount = async () => {
        const savedUserName = localStorage.getItem('username')

        // save claims here
        // await console.log(amount);
        setStakeAmount({
            ZIPT: parseFloat(amount / ZIPTVal).toFixed(0),
            ETH: parseFloat(amount / ETHVal).toFixed(6),
            USD: parseFloat(amount),
        });
        history.push(`/${savedUserName}/wallet_address`);
    };

    return (
        <Auth showBackArrow={true} showPhoto={false} title={'Stake $ZIPT'}>
            <div className="mt-4 px-4 text-gray-600">
                <p className="">
                    To stake your profile’s features, please purchase Zippie
                    ID’s native token $ZIPT with $ETH.
                </p>
                <p className="mt-5">
                    You can estimate your stake in $ETH using the form below.
                    The minimum stake is 5 USD.
                </p>

                <div className="py-4 mt-4">
                    <NumberInput
                        value={amount}
                        min={5}
                        placeholder={`Enter amount`}
                        onChange={GetAmount}
                    >
                        Amount (USD)
                    </NumberInput>

                    <div
                        className="text-purple-600 font-bold cursor-pointer px-2 py-2 text-sm"
                        onClick={CalculateValue}
                    >
                        See equivalent market price
                    </div>
                    {showValue && (
                        <div className="mx-2 py-2 text-xs font-semibold text-gray-600">
                            {`${formatNumber(
                                stakeAmount.USD
                            )} USD = ${formatNumber(
                                stakeAmount.ZIPT
                            )} $ZIPT = ${formatNumber(stakeAmount.ETH)} ETH`}
                        </div>
                    )}
                </div>

                <div className="my-8 text-center">
                    <Button onClick={SaveAmount} disabled={amount < 5}>
                        Next
                    </Button>
                </div>
            </div>
        </Auth>
    );
};

export default Stake;
