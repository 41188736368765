import React from 'react'
import { useHistory } from 'react-router'

import { FullWidthButton } from '../components/Buttons'
import Layout from './Layout'

const Reserved = () => {
  const history = useHistory()

  const isUserLoggedIn =
    localStorage.getItem('isUserLoggedIn') === 'true' ? true : false
  const username = localStorage.getItem('username')

  if (!isUserLoggedIn) history.replace('/')

  const TakeSurvey = () => {
    window.open('https://zippieid.typeform.com/to/ZfNFk6Xt', '_blank')
  }

  return (
    <Layout>
      <div className="mb-10 flex justify-between items-center"></div>
      <div className="my-16 text-center text-base">
        <span className="text-6xl">🎉</span>
        <div className="px-2 py-4">
          <span>We have reserved </span>
          <span className="font-semibold">
            {username !== null ? username : 'username'}
          </span>
          <span> for you. We'll be in touch as soon as possible!</span>
        </div>
        <div className="mt-6 mb-6">
          <p className="mb-4">
            To get onboarded faster, please fill out our survey below.
          </p>
          <div className="flex space-x-1 justify-center">
            <FullWidthButton onClick={TakeSurvey}>Go to Survey</FullWidthButton>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Reserved
