import React from 'react';

const Layout = ({ children }) => {
    return (
        <div className="flex min-h-screen w-full p-4 items-center justify-center text-gray-500 text-sm">
            <div className="bg-white w-full sm:max-w-sm px-4 py-4 rounded-lg shadow-md">
                {children}
            </div>
        </div>
    );
};

export const WideLayout = ({ children }) => {
    return (
      <div className="flex min-h-screen w-full p-4 items-center justify-center text-gray-500 text-sm">
        <div className="bg-white w-full max-w-sm sm:max-w-7xl px-4 py-4 rounded-xl sm:rounded-3xl shadow-md">
          {children}
        </div>
      </div>
    )
};

export default Layout;
