/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate'
import { useEffect, useState } from 'react'
import Vault from '@zippie/vault-api'

import * as constants from '../utils/constants.js'

export const useVault = ({ contentParams }) => {
  const [environment, setEnvironment] = useState()
  const [params, setParams] = useState(contentParams)
  const [vault, setVault] = useState()
  const [vaultIsLoadingOrLoaded, setVaultIsLoadingOrLoaded] = useState(false)
  const [vaultIsReady, setVaultIsReady] = useState(false)

  const clearParam = (param) => {
    let newParams = { ...params }
    if (newParams[param]) {
      delete newParams[param]
      window.parent.postMessage({ wm_remove_url_param: 'paymentHash' }, '*')

      setParams(newParams)
    }
  }

  const closeKlaatuPopUp = () => {
    const style = `z-index: 0; display: none`
    document.getElementById('klaatuembed').style = style
  }

  const openKlaatuPopUp = () => {
    const style = `
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    border: 0;
    z-index: 99999999;
    display: flex;
    background-color: transparent;
    display: block;
    `
    document.getElementById('klaatuembed').style = style
  }

  const handleLoadKlaatu = async () => {
    setVaultIsLoadingOrLoaded(true)
    if (document.getElementById('klaatuembed')) {
      console.error('Loading klaatu again')
      return
    }

    const vaultInstance = new Vault({
      klaatu: true,
      klaatu_embed: {
        root: document.body,
        url: constants.ZIPPIE_URL[getEnv()],
      },
    })
    await vaultInstance.setup()
    setVault(vaultInstance)
    setVaultIsReady(true)
  }

  const getEnv = () => {
    const origin = window.location.origin
    if (origin.includes('dev') || origin.includes('localhost') || origin.includes('ngrok.io')) return 'development'
    if (origin.includes('.test.')) return 'testing'
    return 'release'
  }

  useEffect(() => {
    const env = getEnv()
    setEnvironment(env)

    if (vaultIsLoadingOrLoaded) {
      console.log('*** PREVENTED SECOND LOAD OF VAULT ***')
      return
    }
    handleLoadKlaatu()
  }, [])

  return {
    environment,
    params,
    vault,
    vaultIsReady,
    clearParam,
    openKlaatuPopUp,
    closeKlaatuPopUp,
  }
}

const [VaultProvider, useVaultContext] = constate(useVault)

export { VaultProvider, useVaultContext }
