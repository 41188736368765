import React, { useState } from 'react'
import { uploadProfilePhoto} from '../helpers/DIDs'
import { useDashboardContext } from '../hooks/useDashboard'

import { CreateIPFS } from '../helpers/DIDs'
import { useHistory } from 'react-router'
import { Button } from '../components/Buttons'
import { BasicInput } from '../components/Inputs'
import PhotoUpload from '../components/PhotoUpload'
import Auth from '../layouts/Auth'

const FillFeatures = () => {
  const history = useHistory()
  const features = JSON.parse(localStorage.getItem('features'))
  const {accessToken, getUserProfile,fetchUserInfo, setUserProfile} = useDashboardContext()

  const [{ alt, src, imageFile }, setImg] = useState({
    src: null,
  })

  const [claims, setClaims] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const getClaims = (e) => {
    const {
      target: { id, value },
    } = e
    setClaims((prev) => ({ ...prev, [id]: value }))
  }

  const SaveClaims = async () => {
    setIsLoading(true)
    // save claims here
    const existingUserInfo = await getUserProfile() || {}
    const privateKey = await JSON.parse(localStorage.getItem('privateKey'))

    // generate tokens
    const username = localStorage.getItem('username')
    const response = imageFile ? await uploadProfilePhoto(imageFile, accessToken) : ''
    const newProfile = {
      profileImageCIDPath : response ? response.data.cid: '',
      ...existingUserInfo,
      username, ...claims, 
    }
    const profile = {
      claims: [{ type: 'profile_data', cid: newProfile }],
    }
    await CreateIPFS(privateKey.privkey, profile, accessToken)
    localStorage.removeItem('features')
    const userProfile = await fetchUserInfo()
    setUserProfile(userProfile)
    setIsLoading(false)
    history.push(`/${username}`)
  }

  const previewImage = (e) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        imageFile: e.target.files[0],
        alt: e.target.files[0].name,
      })
    }
  }

  return (
    <Auth showBackArrow={true} showPhoto={false} title={'Add Features'}>
      <div className="mt-4 px-4">
        {features &&
          features.map((feature) => (
            <div className="py-4" key={feature.id}>
              {feature.isImage && (
                <PhotoUpload src={src} alt={alt} onChange={previewImage} />
              )}

              {!feature.isImage && (
                <BasicInput
                  type="text"
                  id={feature.id}
                  value={claims[feature.id]}
                  placeholder={feature.label}
                  onChange={getClaims}
                >
                  {feature.name}
                </BasicInput>
              )}
            </div>
          ))}

        {/* <pre>{JSON.stringify(claims, null, 2)}</pre> */}

        <div className="my-8 text-center">
          <Button onClick={SaveClaims} disabled={isLoading}>
            {isLoading ? 'Submiting...' : 'Save'}
          </Button>
        </div>
      </div>
    </Auth>
  )
}

export default FillFeatures
