import React from 'react';

export const Button = ({ onClick = null, disabled = false, children }) => {
    return (
        <button
            type="button"
            className="bg-purple-700 text-white py-3 px-10 font-semibold rounded-full disabled:opacity-50 disabled:bg-gray-500 disabled:cursor-not-allowed focus:outline-none"
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export const FullWidthButton = ({
    onClick = null,
    disabled = false,
    children,
}) => {
    return (
        <button
            type="button"
            className="px-6 sm:px-16 py-3 bg-purple-700 text-white font-semibold rounded-full  w-full max-w-sm disabled:opacity-50 disabled:bg-gray-500 disabled:cursor-not-allowed focus:outline-none"
            disabled={disabled}
            onClick={onClick}
        >
            <span className="flex justify-center items-center">{children}</span>
        </button>
    );
};

export const OutlineButton = ({
    onClick = null,
    wide = true,
    bold = true,
    className = '',
    children,
}) => {
    return (
        <button
            className={`bg-white hover:bg-purple-700 text-purple-700 w-auto hover:text-white ${
                bold ? 'border-2' : 'border'
            } border-purple-700 px-4 sm:px-6 ${
                wide ? 'py-1.5' : 'py-0.5'
            } sm:py-2 rounded-full focus:outline-none ${
                bold ? 'font-semibold' : ''
            } ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};
