import React from 'react'
import { useHistory } from 'react-router'
import { ProfileCard } from '../../components/Cards'
import { OutlineButton } from '../../components/Buttons'
import Auth from '../../layouts/Auth'

const Profile = () => {
  const history = useHistory()
  return (
    <Auth showBackArrow={false} title={'@zippier'}>
      <div className="flex flex-col">
        <div className="flex items-center justify-between mb-1">
          <div className="flex items-center space-x-2">
            <img
              src="https://pbs.twimg.com/profile_images/3541863016/6ac8c451e0e45a13050ed5f3f1dd25ee.png"
              alt="Master"
              className="h-16 w-16 rounded-full mb-2"
              onClick={() => history.push('/view_photo')}
            />
            <span className="text-purple-800 text-sm font-semibold mb-2 -ml-16">View more</span>
          </div>
          <div className="flex flex-col space-y-2">
            <OutlineButton className="text-sm" onClick={() => alert('View Stakes')}>View Stakes</OutlineButton>
          </div>
        </div>

        <div className="w-full text-gray-800">
          <ProfileCard className="mt-8" onClick={() => history.push('/view_name')}>
            <span>Name: Zippier Master</span>
            <span className="text-purple-900">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </span>
          </ProfileCard>
          <ProfileCard className="mt-8" onClick={() => history.push('/view_twitter')}>
            <span>Twitter handle: @zippier</span>
            <span className="text-purple-900">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </span>
          </ProfileCard>
        </div>
      </div>
    </Auth>
  )
}

export default Profile
